<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12">
					<div class="d-flex py-1 align-items-center">
						<img class="mr-2" src="https://img.icons8.com/windows/32/0C53FB/decentralized-network.png"/><span class="txt-pri page-title">Phân quyền</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row justify-content-lg-between">
            <div class="col-12 col-lg-auto mb-1">
              <div class="d-flex">
              </div>
            </div>
						<div class="col-12 col-lg-auto mb-1">
							<div v-if="show_add" class="d-flex">
									<!-- <button class="btn bg-pri bd-pri text-white btn-icon-add-sm" @click="addPermisions()">Thêm phân quyền</button> -->
                  <!-- <input class="form-control mr-sm-2" type="search" placeholder="Search" aria-label="Search">
                  <button class="btn btn-outline-success my-2 my-sm-0" type="submit">Search</button> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-2 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th class="pl-2">Tên phân quyền</th>
                <th>Slug</th>
                <!-- <th>Nội dung</th>
                <th>Trình trạng</th> -->
							</tr>
						</thead>
						<tbody v-if="permisions && permisions.count && permisions.data" :class="permisions.count > 9 ? 'h-100' : ''">
							<tr v-for="permision in permisions.data" :key="permision.id" class="table-tr mt-2">
								<td data-label="Tên phân quyền" class="table-td-name-avatar pl-2" style="height: 55px;">{{permision && permision.name}}</td>
								<td data-label="Slug">{{permision && permision.slug}}</td>
								<!-- <td>{{permision && permision.content}}</td>
								<td><span v-if="permision" :class="permision.status == 1 ? 'text--red' : 'text--yellow'">{{permision.status == 1 ? 'Action' : 'DeAction'}}</span></td> -->
							</tr>
						</tbody>
					</table>
					<Pagination v-show="permisions && permisions.count" v-if="permisions" :items="permisions" :current_page="current_page" @onRefresh="getPermisions"></Pagination>
				</div>
				<div v-if="permisions && !permisions.count && !loading" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy bệnh nhân mà bạn tìm' : 'Chưa có bệnh nhân nào!' }}</p>
					</div>
				</div>
			</div>
		</div>
    <ModelPermision @onSubmitPermision="onSubmitPermision"></ModelPermision>
	</div>
</template>
<script>
  import appUtils from '../../utils/appUtils'
  import Pagination from '../../components/Pagination'
  import ModelPermision from '../../components/Cms/ModelPermision'
  export default {
    name: 'permisionsListing.vue',
    components: {Pagination, ModelPermision},
    data () {
      return {
        permisions: null,
        loading: false,
        params: {
          limit: 10,
          search: '',
          sort: 'created_at',
          sort_by: 'desc'
        },
        search: '',
        current_page: 0,
        page: 1,
        show_add: true
      }
    },
    mounted () {
      this.page = this.$route.query.page ? this.$route.query.page : 1
      this.getPermisions()
    },
    watch: {
      '$route.query.type': {
        handler: function () {
          let self = this
          self.page = self.$route.query.page
          self.getPermisions()
        },
        deep: true
      }
    },
    methods: {
      async getPermisions () {
        try {
          let self = this
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params = {
            limit: 10,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc',
            page: self.current_page
          }
          const result = await self.$rf.getRequest('AdminRequest').getWorkspacePermissions(self.params)
          self.permisions = result.data
          self.current_page = result.data.current_page
        } catch (e) {
          console.log(e)
        } finally {

        }
      },
      getImageURL (avatar) {
        return appUtils.getImageURL(avatar)
      },
      onShowPermision (show) {
        window.$('#modal--permision').modal(show ? 'show' : 'hide')
      },
      addPermisions () {
        let self = this
        self.onShowPermision(true)
      },
      async onSubmitPermision (params) {
        let self = this
        try {
          await self.$rf.getRequest('AdminRequest').postWorkspacePermissions(params)
          self.getPermisions()
          self.onShowPermision(false)
        } catch (e) {
          console.log(e)
        }
      }
    }
  }
</script>
<style scoped></style>