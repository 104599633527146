<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12">
					<div class="d-flex py-1 align-items-center">
						<img class="mr-2" src="https://img.icons8.com/windows/50/0C53FB/how-many-quest.png"/><span class="txt-pri page-title">Tags</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row justify-content-lg-between">
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
							</div>
						</div>
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
								<button class="btn bg-pri bd-pri text-white btn-icon-add-sm" @click="addTag()">Thêm tags</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-4 pl-4 pr-4 border-0">
        <!-- {{tags}} -->
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Tên tags</th>
                <th>Kiểu</th>
								<th>Thời gian tạo</th>
                <th>Chức năng</th>
							</tr>
						</thead>
						<tbody v-if="tags && tags.count && tags.data" style="min-height: 600px;">
							<tr v-for="tag in tags.data" :key="tag.id">
								<td>{{tag ? tag.name ? tag.name : '' : ''}}</td>
								<td v-html="tag ? tag.created_at ? formatWeekdayTime(tag.created_at) : '' : ''"></td>
                <td v-if="tag && tag.name">{{tag.type === 1 ? 'CURRENT' : tag.type === 1 ? 'PREVIOUS' : ''}}</td>
                <td>
                  <button class="btn btn-outline-primary btn-sm" @click="onUpdate(tag)">Chỉnh sửa</button>
                  <button class="btn btn-outline-danger btn-sm ml-2 d-none" @click="onDelete(tag.id)">Xoá</button>
                </td>
							</tr>
						</tbody>
					</table>
					<Pagination v-show="tags && tags.count" v-if="tags" :items="tags" :current_page="current_page" @onRefresh="getTags"></Pagination>
				</div>
				<div v-if="tags && !tags.count && !loading" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy thẻ tag mà bạn tìm' : 'Chưa có thẻ tag nào!' }}</p>
					</div>
				</div>
			</div>
		</div>
    <ModelTags :edit="tag" @onSubmitUpdate="onSubmitUpdate" @onSubmit="onSubmit"></ModelTags>
	</div>
</template>
<script>
  import ModelTags from '../../components/Cms/ModelTags'
  import Pagination from '../../components/Pagination'
  export default {
    name: 'tagsListing',
    components: {Pagination, ModelTags},
    data () {
      return {
        tags: null,
        loading: false,
        params: {
          limit: 10,
          search: '',
          sort: 'created_at',
          sort_by: 'desc'
        },
        search: '',
        current_page: 0,
        page: 1,
        tag: ''
      }
    },
    mounted () {
      this.page = this.$route.query.page ? this.$route.query.page : 1
      this.getTags()
    },
    watch: {
      '$route.query.type': {
        handler: function () {
          let self = this
          self.page = self.$route.query.page
          self.getTags()
        },
        deep: true
      }
    },
    methods: {
      async getTags () {
        try {
          let self = this
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params = {
            limit: 10,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc',
            page: self.current_page
          }
          const result = await self.$rf.getRequest('AuthRequest').getTags()
          self.tags = result.data
          self.current_page = result.data.current_page
        } catch (e) {
          console.log(e)
        } finally {

        }
      },
      formatWeekdayTime (date_time) {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      },
      onModel (show) {
        window.$('#modal--tags').modal(show ? 'show' : 'hide')
      },
      addTag () {
        let self = this
        self.tag = ''
        self.onModel(true)
      },
      async onDelete (id) {
        let self = this
        try {
          var r = confirm('Bạn có xoá tag này không?')
          if (r) {
            await self.$rf.getRequest('AdminRequest').postTagsDelete(id).then(res => {
              self.getTags()
            })
            self.$toast.clear()
            self.$toast.open({
              message: 'Xoá tag thành công',
              type: 'success'
            })
          }
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      onUpdate (item) {
        let self = this
        self.tag = item
        self.onModel(true)
      },
      async onSubmitUpdate (data) {
        let self = this
        try {
          await self.$rf.getRequest('AdminRequest').postTagsId(data.id, data).then(res => {
            self.getTags()
            self.onModel(false)
          })
          self.$toast.clear()
          self.$toast.open({
            message: 'Chỉnh sửa tag thành công',
            type: 'success'
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      async onSubmit (data) {
        let self = this
        try {
          var dt = {
            tags: []
          }
          dt.tags.push(data)
          await self.$rf.getRequest('AdminRequest').postTags(dt).then(res => {
            self.getTags()
            self.onModel(false)
          })
          self.$toast.clear()
          self.$toast.open({
            message: 'Thêm loại tag thành công',
            type: 'success'
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      }
    }
  }
</script>
<style scoped></style>