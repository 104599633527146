<template>
    <div class="row">
        <div class="telemedicine-management pr-3">
            <div class="row pl-3">
                <div class="col-12">
                    <div class="d-flex py-1 align-items-center">
                        <i class="fa fa-commenting-o mr-2" aria-hidden="true" style="font-size: 30px; color: #20419B;"></i><span class="txt-pri page-title">Gửi tin nhắn In-App</span>
                    </div>
                </div>
            </div>
            <div class="card radius-10 pt-2 pl-4 pr-4 border-0 mt-3">
                <div class="row">
                    <div class="col-12">
                        <div class="form-group">
                            <label class="robo-20-500" for="title_noti">Tiêu đề</label>
                            <input type="text" v-model="params.title" class="form-control mt-2" id="title_noti" placeholder="Tiêu đề thông báo" autocomplete="off">
                            <div id="err_title" class="text-warning">{{error.err_title}}</div>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="form-group">
                            <label class="robo-20-500" for="title_noti">Nội dung thông báo</label>
                            <textarea class="form-control mt-2" v-model="params.body" rows="5"></textarea>
                            <small id="err_context" class="text-warning">{{error.err_context}}</small>
                        </div>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="form-group">
                            <label class="robo-20-500" for="title_noti">Đối tượng & cách thức nhận</label><br>
                            <small id="err_context" class="text-warning">{{error.err_object}}</small>
                            <div class="row mt-2">
                                <div class="col-2">
                                    <div class="list-group" id="list-tab" role="tablist">
                                        <a class="list-group-item list-group-item-action robo-16-500" id="list-home-list" data-toggle="list" href="#list-home" role="tab" aria-controls="home" @click="changeObj(1)">Gửi hàng loạt</a>
                                        <a class="list-group-item list-group-item-action active robo-16-500" id="list-profile-list" data-toggle="list" href="#list-profile" role="tab" aria-controls="profile" @click="changeObj(2)">Gửi theo danh sách</a>
                                    </div>
                                </div>
                                <div :class=" typeObj == 1 ? 'col-10' : typeObj == 2 ? 'col-12' : ''">
                                    <div class="tab-content" id="nav-tabContent">
                                        <div class="tab-pane fade d-flex align-content-center" id="list-home" role="tabpanel" aria-labelledby="list-home-list">
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="CheckboxPatient" value="1" :checked="params.is_patient" @click="params.is_patient = !params.is_patient">
                                                <label class="form-check-label" for="CheckboxPatient">Toàn bộ bệnh nhân</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="CheckboxDoctor" value="2" :checked="params.is_doctor" @click="params.is_doctor = !params.is_doctor">
                                                <label class="form-check-label" for="CheckboxDoctor">Toàn bộ bác sĩ</label>
                                            </div>
                                            <div class="form-check form-check-inline">
                                                <input class="form-check-input" type="checkbox" id="CheckboxCancer" value="3" :checked="params.is_cancer_user" @click="params.is_cancer_user = !params.is_cancer_user">
                                                <label class="form-check-label" for="CheckboxCancer">Toàn bệnh nhân ung thư</label>
                                            </div>
                                        </div>
                                        <div class="tab-pane fade show active" id="list-profile" role="tabpanel" aria-labelledby="list-profile-list">
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <div class="btn-group" role="group" aria-label="Basic example">
                                                        <button type="button" class="btn" :class="type == 1 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(1)">Bệnh nhân</button>
                                                        <button type="button" class="btn" :class="type == 2 ? 'btn--blue' : 'btn--babyblue'" @click="changeType(2)">Bác sĩ</button>
                                                    </div>
                                                </div>
                                                <div class="d-flex">
                                                    <input type="text" class="form-control" v-model="search" @keyup.enter="searchPerson()" @keyup.delete="searchPersonDelete()"><button class="btn bg-pri bd-pri text-white" @click="searchPerson()">Tìm kiếm</button>
                                                </div>
                                            </div>
                                            <div class="row">
                                                <div class="col-md-7">
                                                    <DoctorListInSendMess v-if="type == 2" :list_doctor="list_doctor" :doctors="doctors" @sendListFormDoctorToDad="sendListFormDoctorToDad"></DoctorListInSendMess>
                                                    <Pagination v-if="doctors && type == 2" v-show="doctors && doctors.count" :items="doctors" :current_page="current_page" @onRefresh="getDoctor"></Pagination>
                                                    <UserListInSendMess v-if="type == 1" :list_user="list_user" :persons="persons" @sendListFormUserToDad="sendListFormUserToDad"></UserListInSendMess>
                                                    <Pagination v-if="persons && type == 1" v-show="persons && persons.count" :items="persons" :current_page="current_page" @onRefresh="getPersons"></Pagination>
                                                </div>
                                                <div class="col-md-5 border-left pt-3 selected-user">
                                                    <p class="text-black robo-20-500">Đã chọn</p>
                                                    <p class="txt-pri robo-18-500" v-if="list_doctor && list_doctor.length"> * Bác sĩ: </p>
                                                    <p class="text-black robo-16-500 ml-3" v-if="list_doctor && list_doctor.length" v-for="dtr in list_doctor"> - {{dtr.name}}</p>
                                                    <p class="txt-pri robo-18-500" v-if="list_user && list_user.length"> * Bệnh nhân: </p>
                                                    <p class="text-black robo-16-500 ml-3" v-if="list_user && list_user.length" v-for="ptn in list_user"> - {{ptn && ptn.person && ptn.person.name ? ptn.person.name : ''}}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="form-group mt-3">
                            <label for="staticEmail2" class="robo-16-500 mr-2">Cách thức nhận</label>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="background_noti" value="1" :checked="params.is_bg" @click="params.is_bg = !params.is_bg">
                                <label class="form-check-label" for="background_noti">Background notification</label>
                            </div>
                            <div class="form-check form-check-inline">
                                <input class="form-check-input" type="checkbox" id="internal_noti" value="2" :checked="params.is_internal" @click="params.is_internal = !params.is_internal">
                                <label class="form-check-label" for="internal_noti">Internal Notification</label>
                            </div><br>
                            <small id="err_context" class="text-warning">{{error.err_methord}}</small>
                        </div>
                        <div class="col-12 mt-3">
                            <div class="form-group">
                                <label class="robo-20-500" for="link_noti">Link đi kèm, khi click vào sẽ mở ra webview (nếu có)</label>
                                <input type="text" class="form-control mt-2" v-model="params.url" id="link_noti" placeholder="Tiêu đề thông báo">
                            </div>
                        </div>
                        <div class="col-sm-12 mt-4">
                            <div class="row justify-content-lg-end">
                                <div class="col-12 col-lg-auto mb-1">
                                    <div class="d-flex">
                                        <button class="btn bg-pri bd-pri text-white" @click="sendMessages()"> <img class="mr-2" src="../../../public/assets/images/icon/icon_send.svg" alt="">Gửi thông báo</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div><br><br>
        </div>
    </div>
    </div>
</template>
<script>
import Pagination from '../../components/Pagination'
import UserListInSendMess from '../../components/Cms/UserListInSendMess'
import DoctorListInSendMess from '../../components/Cms/DoctorListInSendMess'
export default {
  name: 'sendMessagesInApp',
  components: { Pagination, UserListInSendMess, DoctorListInSendMess },
  data () {
    return {
      persons: null,
      current_page: 0,
      error: {
        err_title: '',
        err_context: '',
        err_object: '',
        err_methord: ''
      },
      list_check: [],
      params: {
        title: '',
        body: '',
        is_patient: false,
        is_doctor: false,
        is_bg: false,
        is_internal: false,
        user_ids: [],
        doctor_ids: [],
        url: '',
        is_cancer_user: false
      },
      list_user: [],
      search: '',
      typeObj: 2,
      type: 1,
      doctors: null,
      list_doctor: []
    }
  },
  mounted () {
    this.page = this.$route.query.page ? this.$route.query.page : 1
    this.getPersons()
    this.getDoctor()
  },
  methods: {
    async sendMessages () {
      let self = this
      try {
        self.list_check = self.list_user.concat(self.list_doctor)
        if (!self.params.title) {
          self.error.err_title = 'Không để tiêu đề trống'
          return false
        } else if (!self.params.body) {
          self.error.err_title = ''
          self.error.err_context = 'Không để nội dung trống'
          return false
        } else if (!self.params.is_doctor && !self.params.is_cancer_user && !self.params.is_patient && self.list_check.length === 0) {
          self.error.err_context = ''
          self.error.err_object = 'Không để đối tượng trống'
          return false
        } else if (!self.params.is_bg && !self.params.is_internal) {
          self.error.err_object = ''
          self.error.err_methord = 'Không để phương thức trống'
          return false
        } else {
          self.error.err_methord = ''
          let r = ''
          if (!self.params.is_doctor && !self.params.is_patient && !self.params.is_cancer_user) {
            self.params.user_ids = self.getIds(self.list_user)
            self.params.doctor_ids = self.getIds(self.list_doctor)
            r = confirm('Bạn có chắc chắn gửi đến những người này?')
          }
          if (self.params.is_doctor) {
            r = confirm('Bạn có chắc muốn gửi tin nhắn cho TOÀN BỘ BÁC SĨ HODO?')
          }
          if (self.params.is_patient) {
            r = confirm('Bạn có chắc muốn gửi tin nhắn cho TOÀN BỘ BỆNH NHÂN HODO?')
          }

          if (self.params.is_cancer_user) {
            r = confirm('Bạn có chắc muốn gửi tin nhắn cho TOÀN BỘ BỆNH NHÂN UNG THƯ HODO?')
          }
          if (r) {
            await self.$rf.getRequest('AdminRequest').sendMessage(self.params).then((resp) => {
              self.$toast.clear()
              self.$toast.open({
                message: 'Gửi message thành công',
                type: 'success'
              })
              self.reset()
            })
          }
        }
      } catch (e) {
        console.log(e)
      }
    },
    async getPersons () {
      try {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 10,
          search: self.search,
          order: 'desc',
          order_by: 'created_at',
          page: self.current_page,
          is_active: 1
        }
        const result = await self.$rf.getRequest('AdminRequest').getUser(params)
        self.persons = result.data
        self.current_page = result.data.current_page
      } catch (e) {
        console.log(e)
      } finally {

      }
    },
    sendListFormUserToDad (list) {
      this.list_user = list
      console.log(this.list_user)
    },
    sendListFormDoctorToDad (list) {
      this.list_doctor = list
      console.log(this.list_doctor)
    },
    async getDoctor () {
      try {
        let self = this
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 10,
          search: self.search,
          order: 'desc',
          order_by: 'created_at',
          page: self.current_page
        }
        const result = await self.$rf.getRequest('AdminRequest').getDoctors(params)
        self.doctors = result.data
        self.current_page = result.data.current_page
      } catch (e) {
        console.log(e)
      } finally {

      }
    },
    changeType (type) {
      this.type = type
      var route = this.$route
      if (route.fullPath !== '/admin/send-message') {
        this.$router.push({ path: '/admin/send-message' }).catch(() => {})
      }
      this.getPersons()
      this.getDoctor()
    },
    changeObj (type) {
      this.typeObj = type
    },
    searchPerson () {
      var route = this.$route
      if (route.fullPath !== '/admin/send-message') {
        this.$router.push({ path: '/admin/send-message' }).catch(() => {})
      }
      this.getPersons()
      this.getDoctor()
    },
    searchPersonDelete () {
      if (this.search.length === 0) {
        var route = this.$route
        if (route.fullPath !== '/admin/send-message') {
          this.$router.push({ path: '/admin/send-message' }).catch(() => {})
        }
        this.getPersons()
        this.getDoctor()
      }
    },
    getIds (array) {
      if (array && array.length) {
        let ids = []
        array.forEach((item) => {
          ids.push(item.id)
        })
        return ids
      }
    },
    reset () {
      let self = this
      self.current_page = 0
      self.error = {
        err_title: '',
        err_context: '',
        err_object: '',
        err_methord: ''
      }
      self.list_check = []
      self.params = {
        title: '',
        body: '',
        is_patient: false,
        is_doctor: false,
        is_bg: false,
        is_internal: false,
        user_ids: [],
        doctor_ids: [],
        url: ''
      }
      self.list_user = []
      self.typeObj = 2
      self.type = 1
      self.list_doctor = []
      self.getPersons()
      self.getDoctor()
    }
  }
}
</script>
<style scoped>
.list-group-item.active {
    background-color: #20419B;
}
.selected-user {
  max-height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>