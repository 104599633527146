<template>
	<div class="telemedicine-management pr-3">
		<div class="row">
			<div class="col-12">
				<div class="d-flex py-1 align-items-center">
					<img src="../../../public/assets/images/icon/big_clinic_blue.svg" style="height: 40px; width: 40px;" alt="" class="mr-2"><span class="txt-pri page-title">Thông tin ngân hàng</span>
				</div>
			</div>
		</div>
        <div class="bg-white mt-2">
        	<div class="p-2">
        		<div>
					<BankInformation></BankInformation>
				</div>
        	</div>
        </div>
    </div>
</template>
<script>
import BankInformation from '../../components/BankInformation.vue'
export default {
  name: 'bankAccountListing',
  components: {BankInformation},
  data () {
    return {}
  }
}
</script>
<style scoped></style>