<template>
  <div class="container-window mt-3 mr-3 border bd-e4 px-3">
    <HeaderWindow
      @onClose="onClose"
      :title="title"
      :show_calll="true"
    />
    <div class="row mt-4 mb-4">
      <div
        class="col-sm-8 offset-sm-2"
        style="box-shadow: 0px 4px 0px 0px #E9E9E9;"
      >
        <div v-if="topic && topic.type != 6 && topic.type != 7">
          <div class="robo-24-500 txt-pri-corl">Nội dung: </div>
          <div
            class="mt-2 robo-18-400 mb-2"
            v-if="topic"
          >{{topic.content ? topic.content : getTitle(topic)}}</div>
          <div class="mt-1 mb-3">
            <div
              align="center"
              class="border-itemv3 robo-16-400 row"
              v-if="topic && topic.medias"
            >
              <div
                class="col-3 mt-2 thread-medias-img"
                v-for="(item, index) in topic.medias"
                :key="index"
                :style="`background-image: url('${getImageURL(item.path)}');`"
                @click="onShowMedia(topic.medias, index)"
              >
              </div>
            </div>
          </div>
        </div>
        <div v-if="topic && (topic.type == 6 || topic.type == 7)">
          <div v-if="topic.attach && topic.attach.method && topic.attach.method == 2">
            <div class="robo-24-500 txt-pri-corl"> <img
                src="../../../public/assets/images/icon/icon-topic-video.svg"
                width="28"
                height="28"
              > Cuộc gọi video</div>
          </div>
          <div v-if="topic.attach && topic.attach.method && topic.attach.method == 1">
            <div class="robo-24-500 txt-pri-corl"> <img
                src="../../../public/assets/images/icon/icon-topic-walk.svg"
                width="28"
                height="28"
              >{{ $t("workspace_setting.lbl_appointment_to_workspace")[$orgModel] }}</div>
          </div>
          <p class="mt-3"><span class="robo-18-500">Thời gian: </span><span class="robo-18-400">{{topic && topic.attach && topic.attach.start_time && formatHMNDMY(topic.attach.start_time)}}</span></p>
          <p><span class="robo-18-500">Ghi chú: </span><span class="robo-18-400">{{topic && topic.attach && topic.attach.note}}</span></p>
        </div>
      </div>
      <div class="col-sm-8 offset-sm-2">
        <div
          class="mb-3"
          v-if="comments && comments.data"
        >
          <div
            class="mt-3 border-itemv3 border-bottom"
            v-for="(comment, index) in comments.data"
            :key="index"
          >
            <div
              class="request-person-info d-flex flex-shrink-1"
              :set="user = getUserCreate(comment)"
            >
              <div class="person-avatar">
                <div class="person-avatar">
                  <span
                    v-if="hasAvatar(user)"
                    class="avatar avatar-sm avatar-rounded"
                    :style="'background-image: url('+getImageURL(user.data.user.avatar)+')'"
                    style="height: 48px; width: 48px;"
                  ></span>
                  <span
                    v-if="!hasAvatar(user)"
                    class="avatar avatar-sm avatar-rounded text-white bg-dark"
                    style="height: 48px; width: 48px;"
                  >
                    <span class="robo-14-500">{{user && user.data && user.data && user.data.name && user.data.name.charAt(0)}}</span>
                  </span>
                </div>
              </div>
              <div class="ml-3 w-100 mb-2">
                <div class="form-group">
                  <div class="d-flex justify-content-between align-items-center mb-1">
                    <p class="robo-18-500 text-gray mb-0">{{user && user.data && user.data.name}}</p>
                    <p class="robo-16-400 mb-0">{{comment && comment.created_at ? formatHMNDMY(comment.created_at) : ''}}</p>
                  </div>
                  <p class="robo-16-400 txt-grey-900 mb-2">{{comment && comment.content}}</p>
                  <div class="mt-1">
                    <div
                      align="center"
                      class="border-itemv3 robo-16-400 row"
                      v-if="comment && comment.medias"
                    >
                      <div
                        class="col-3 mt-2 thread-medias-img"
                        v-for="(item, index) in comment.medias"
                        :key="index"
                        :style="`background-image: url('${getImageURL(item.path)}');`"
                        @click="onShowMedia(comment.medias, index)"
                      >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          v-show="comments && comments.count"
          :items="comments"
          :current_page="current_page"
          @onRefresh="getTopicById"
        ></Pagination>
        <div
          class="mb-3"
          v-if="comments && !comments.count"
        >
          <div class="mt-3">
            <div class="robo-16-500 text-center">Không có dữ liệu</div>
          </div>
        </div>
      </div>
    </div>
    <CoolLightBox
      :items="getImagesForCoolight(list_medias)"
      :index="index_media"
      :useZoomBar="true"
      @close="index_media = null"
    ></CoolLightBox>
  </div>
</template>
<script>
import appUtils from '../../utils/appUtils.js'
import Pagination from '../../components/Pagination'
import HeaderWindow from '../../components/Diseases/HeaderWindow.vue'
export default {

  name: 'TopicDetail',
  components: { Pagination, HeaderWindow },
  data () {
    return {
      show: 'topic',
      title: '',
      person_diseases_id: null,
      comments: null,
      list_medias: null,
      index_media: '',
      topic: null,
      current_page: 1
    }
  },
  mounted () {
    let self = this
    self.person_diseases_id = self.$route?.params?.id
    self.getTopicById()
    self.getTopicId()
  },
  methods: {
    onClose () {
      let self = this
      self.$router.push({ path: `/admin/diseases/${self.$route?.params?.id}/topic` })
    },
    getUserCreate (comment) {
      var result = {
        data: null
      }
      if (comment.person) {
        result.data = comment.person
      } else if (comment.doctor) {
        result.data = comment.doctor
      }
      return result
    },
    hasAvatar (user) {
      return user && user.data && user.data.user && user.data.user.avatar
    },
    getImageURL (avatar) {
      return appUtils.getImageURL(avatar)
    },
    formatHMNDMY (date_time) {
      let str = window.moment(date_time).format('HH:mm DD/MM/YY')
      return str.replace(' ', ' ngày ')
    },
    getImagesForCoolight (files) {
      let results = []
      if (files) {
        files.forEach(file => {
          results.push({
            title: file.name,
            src: appUtils.getImageURL(file.path)
          })
        })
      }
      return results
    },
    onShowMedia (medias, index) {
      let self = this
      self.list_medias = medias
      self.index_media = index
    },
    async getTopicById () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var params = {
          limit: 10,
          sort: 'created_at',
          sort_by: 'desc',
          page: self.current_page
        }
        await self.$rf.getRequest('AdminRequest').getTopicById(self.$route.query.id, params).then(res => {
          if (res && res.data) {
            self.comments = res.data
          }
        })
      } catch (e) {
        console.log(e)
      }
    },
    getTitle (topic) {
      let type = topic.type
      let attach = topic.attach
      if (type === 1 || type === 3) {
        return 'Đã tạo hồ sơ chăm sóc'
      }
      if (type === 4) {
        return 'HODO bắt đầu Hồ sơ sức khỏe'
      }
      if (type === 2 || type === 5 || type === 9) {
        return 'Đã cập nhật thông tin sức khoẻ'
      }
      if (type === 6 || type === 7) {
        let str = attach.method === 2 ? 'Cuộc gọi Video Call ' : 'Hẹn đến cơ sở y tế '
        let date = this.formatHMNDMY(attach.start_time)
        return str + date
      }
      if (type === 8) {
        return topic.content
      }
      if (type === 10) {
        return 'Dịch vụ ' + attach.service_name
      }
    },
    async getTopicId () {
      let self = this
      try {
        await self.$rf.getRequest('AdminRequest').getTopicId(self.$route.query.id).then(res => {
          if (res && res.data) {
            self.topic = res.data
            if (self.topic.type === 6 || self.topic.type === 7) {
              self.title = 'Lịch hẹn'
            } else {
              self.title = 'Trao đổi với bệnh nhân'
            }
          }
        })
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
<style lang="css" scoped>
.thread-medias-img {
  width: 126px;
  height: 126px;
  border-radius: 12px;
  background-size: cover;
  cursor: pointer;
}
</style>