<template>
  <div class="row">
    <div class="telemedicine-management pr-3">
      <div class="row pl-3">
        <div class="col-12">
          <div class="d-flex py-1 align-items-center">
            <i
              class="fa fa-question-circle-o mr-2"
              aria-hidden="true"
              style="font-size: 35px; color: #20419B;"
            ></i>
            <span class="txt-pri page-title">Câu hỏi</span>
          </div>
        </div>
      </div>
      <div class="card radius-10 pt-2 pl-4 pr-4 border-0 mt-3">
        <!-- <div class="row">
					<div class="col-sm-8 offset-2">
						<div class="input-group-lg">
							<label class="robo-16-500">Tiêu đề</label>
							<input type="text" class="form-control mt-1" aria-label="Large" aria-describedby="inputGroup-sizing-sm" placeholder="Tiêu đề biểu mẫu" v-model="title">
						</div>
						<div class="input-group-sm mb-3 mt-3">
							<label class="robo-16-500">Mô tả</label>
							<input type="text" class="form-control mt-1" aria-label="Small" aria-describedby="inputGroup-sizing-sm" placeholder="Mô tả biểu mẫu" v-model="describe">
						</div>
					</div>
        </div>-->
        <div class="row">
          <div align="right">
            <button class="btn bg-pri bd-pri text-white" @click="addQuestion()">Thêm câu hỏi</button>
          </div>
        </div>
        <div class="text-center robo-24-500">Danh sách câu hỏi</div>
        <div v-if="questions && questions.count">
          <div class="row mt-2" v-for="(item, index) in questions.data" :key="index">
            <div class="col-sm-8 offset-2" style="border-radius: 10px; border: 1px solid #F8FAFF;">
              <div style="padding: 15px 15px;">
                <div class="row">
                  <div class="col-sm-8">
                    <label class="robo-16-500">Tiêu đề câu hỏi</label>
                    <input
                      type="text"
                      class="form-control mt-1 disabled"
                      aria-label="Large"
                      aria-describedby="inputGroup-sizing-sm"
                      placeholder="Tiêu đề biểu mẫu"
                      :value="item.content"
                    />
                  </div>
                  <div class="col-sm-4 d-flex align-items-end justify-content-end">
                    <div class="dropdown">
                      <button
                        class="btn btn-secondary dropdown-toggle disabled"
                        type="button"
                        id="dropdownMenuButton"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >{{item.type == 1 ? 'Trả lời ngắn' : item.type == 2 ? 'Trắc nghiệm' : '' }}</button>
                      <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click="changeStatus(1)"
                        >Văn bản ngắn</a>
                        <!-- <a class="dropdown-item" href="#">Đoạn</a> -->
                        <a
                          class="dropdown-item"
                          href="javascript:void(0)"
                          @click="changeStatus(2)"
                        >Trắc nghiệm</a>
                        <!-- <a class="dropdown-item" href="#">Hộp kiểm</a> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="mt-2 border-top">
                  <div class="row mt-2">
                    <div class="col-sm-6">
                      Hoạt động
                      <div class="btn-group" role="group" aria-label="Basic example">
                        <button
                          type="button"
                          class="btn btn-sm disabled"
                          :class="item.status === 1 ? 'btn--blue' : 'btn--babyblue'"
                          @click="changeQuestion(1)"
                        >Off</button>
                        <button
                          type="button"
                          class="btn btn-sm disabled"
                          :class="item.status === 2 ? 'btn--blue' : 'btn--babyblue'"
                          @click="changeQuestion(2)"
                        >On</button>
                      </div>
                    </div>
                    <div align="right" class="col-sm-6">
                      <button
                        class="btn btn-sm bg-pri bd-pri text-white"
                        @click="editQuestion(item)"
                      >Chỉnh sửa</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          v-show="questions && questions.count"
          :items="questions"
          :current_page="current_page"
          @onRefresh="getQuestions"
        ></Pagination>
        <ModelQuestion :is_edit="is_edit" :data="data" @onSubmitQuestion="onSubmitQuestion"></ModelQuestion>
        <br />
        <br />
      </div>
    </div>
  </div>
</template>
<script>
import Pagination from '../../components/Pagination'
import ModelQuestion from '../../components/Cms/ModelQuestion'
export default {
  name: 'questionListing',
  components: { Pagination, ModelQuestion },
  data () {
    return {
      tilte: 'Mẫu không có tiêu đề',
      describe: '',
      style_question: 'Loại trả lời',
      style_number: 1,
      status_question: 1,
      questions: null,
      current_page: 1,
      is_edit: false,
      data: null
    }
  },
  mounted () {
    this.getQuestions()
  },
  methods: {
    changeQuestion (type) {
      let self = this
      self.status_question = type
    },
    changeStatus (type) {
      let self = this
      self.style_number = type
      if (type === 1) {
        self.style_question = 'Trả lời ngắn'
      } else if (type === 2) {
        self.style_question = 'Trắc nghiệm'
      }
    },
    async getQuestions () {
      let self = this
      try {
        self.current_page = parseInt(self.$route.query.page) || 1
        var param = {
          page: self.current_page
        }
        let result = await self.$rf
          .getRequest('AdminRequest')
          .getQuestions(param)
        self.questions = result.data
        self.is_edit = false
        self.data = null
      } catch (e) {
        // statements
        console.log(e)
      }
    },
    editQuestion (data) {
      let self = this
      self.data = data
      self.is_edit = true
      self.showModalQuestion(true)
    },
    addQuestion () {
      let self = this
      self.is_edit = false
      self.showModalQuestion(true)
    },
    showModalQuestion (show) {
      window.$('#modal--question').modal(show ? 'show' : 'hide')
    },
    async onSubmitQuestion (data, edit) {
      let self = this
      try {
        if (edit) {
          await self.$rf
            .getRequest('AdminRequest')
            .postQuestionsEdit(data.id, data)
            .then(res => {
              self.getQuestions()
            })
          self.$toast.clear()
          self.$toast.open({
            message: 'Chỉnh sửa câu hỏi thành công',
            type: 'success'
          })
        } else {
          await self.$rf
            .getRequest('AdminRequest')
            .postQuestions(data)
            .then(res => {
              self.getQuestions()
            })
          self.$toast.clear()
          self.$toast.open({
            message: 'Thêm câu hỏi thành công',
            type: 'success'
          })
        }
      } catch (e) {
        // statements
        console.log(e)
      } finally {
        self.showModalQuestion(false)
      }
    }
  }
}
</script>
<style scoped></style>