<template>
	<div class="row">
		<div class="telemedicine-management pr-3">
			<div class="row pl-3">
				<div class="col-12">
					<div class="d-flex py-1 align-items-center">
						<img class="mr-2" src="https://img.icons8.com/windows/50/0C53FB/how-many-quest.png"/><span class="txt-pri page-title">Loại bệnh</span>
					</div>
				</div>
				<div class="col-sm-12">
					<div class="row justify-content-lg-between">
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
							</div>
						</div>
						<div class="col-12 col-lg-auto mb-1">
							<div class="d-flex">
								<button class="btn bg-pri bd-pri text-white btn-icon-add-sm" @click="addDiseases()">Thêm loại bệnh</button>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="card radius-10 pt-4 pl-4 pr-4 border-0">
				<div class="table-responsive">
					<table class="table table-vcenter table-mobile-md card-table table-scroll">
						<thead>
							<tr>
								<th>Tên loại bệnh</th>
								<th>Thời gian tạo</th>
								<th>Chức năng</th>
							</tr>
						</thead>
						<tbody v-if="diseases && diseases.count && diseases.data" style="min-height: 600px;">
							<tr v-for="disease in diseases.data" :key="disease.id">
								<td>{{disease ? disease.name ? disease.name : '' : ''}}</td>
								<td v-html="disease ? disease.created_at ? formatWeekdayTime(disease.created_at) : '' : ''"></td>
								<td>
									<button class="btn btn-outline-primary btn-sm" @click="onUpdate(disease)">Chỉnh sửa</button>
									<button class="btn btn-outline-danger btn-sm ml-2 d-none" @click="onDelete(disease.id)">Xoá</button>
								</td>
							</tr>
						</tbody>
					</table>
					<Pagination v-show="diseases && diseases.count" v-if="diseases" :items="diseases" :current_page="current_page" @onRefresh="getdiseases"></Pagination>
				</div>
				<div v-if="diseases && !diseases.count && !loading" class="row">
					<div class="col-sm-4 offset-sm-4 pt-3 pb-5 text-center">
						<img src="../../../public/assets/images/not-found-search.png" class="img-responsive p-0" alt="">
						<p class="robo-16-400 text-black">{{search ? 'HODO không tìm thấy loại bệnh mà bạn tìm' : 'Chưa có loại bệnh nào!' }}</p>
					</div>
				</div>
			</div>
		</div>
    <ModelDiseases :edit="disease" @onSubmitUpdate="onSubmitUpdate" @onSubmit="onSubmit"></ModelDiseases>
	</div>
</template>
<script>
  import ModelDiseases from '../../components/Cms/ModelDiseases'
  import Pagination from '../../components/Pagination'
  export default {
    name: 'diseasesListing',
    components: { Pagination, ModelDiseases },
    data () {
      return {
        diseases: null,
        loading: false,
        params: {
          limit: 10,
          search: '',
          sort: 'created_at',
          sort_by: 'desc'
        },
        search: '',
        current_page: 0,
        page: 1,
        disease: null
      }
    },
    mounted () {
      this.page = this.$route.query.page ? this.$route.query.page : 1
      this.getdiseases()
    },
    watch: {
      '$route.query.type': {
        handler: function () {
          let self = this
          self.page = self.$route.query.page
          self.getdiseases()
        },
        deep: true
      }
    },
    methods: {
      async getdiseases () {
        try {
          let self = this
          self.current_page = parseInt(self.$route.query.page) || 1
          self.params = {
            limit: 10,
            search: self.search,
            sort: 'created_at',
            sort_by: 'desc',
            page: self.current_page
          }
          const result = await self.$rf.getRequest('AdminRequest').getDiseases(self.params)
          self.diseases = result.data
          self.current_page = result.data.current_page
        } catch (e) {
          console.log(e)
        } finally {

        }
      },
      formatWeekdayTime (date_time) {
        var split_time = window.moment(date_time).format('DD/MM/YYYY HH:mm ').split(' ')
        var str_time = split_time[1] + '<br><small class="robo-12-400 text-muted">' + split_time[0] + '</small>'
        return str_time
      },
      onModel (show) {
        window.$('#modal--diseases').modal(show ? 'show' : 'hide')
      },
      addDiseases () {
        let self = this
        self.disease = ''
        self.onModel(true)
      },
      async onDelete (id) {
        let self = this
        try {
          var r = confirm('Bạn có xoá loại triệu chứng này không?')
          if (r) {
            await self.$rf.getRequest('AdminRequest').postDiseasesDelete(id).then(res => {
              self.getdiseases()
            })
            self.$toast.clear()
            self.$toast.open({
              message: 'Xoá loại triệu chứng thành công',
              type: 'success'
            })
          }
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      onUpdate (item) {
        let self = this
        self.disease = item
        self.onModel(true)
      },
      async onSubmitUpdate (data) {
        let self = this
        try {
          await self.$rf.getRequest('AdminRequest').postDiseasesId(data.id, data).then(res => {
            self.getdiseases()
            self.onModel(false)
          })
          self.$toast.clear()
          self.$toast.open({
            message: 'Chỉnh sửa loại triệu chứng thành công',
            type: 'success'
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      },
      async onSubmit (data) {
        let self = this
        try {
          await self.$rf.getRequest('AdminRequest').postDiseases(data).then(res => {
            self.getdiseases()
            self.onModel(false)
          })
          self.$toast.clear()
          self.$toast.open({
            message: 'Thêm loại triệu chứng thành công',
            type: 'success'
          })
        } catch (e) {
          // statements
          console.log(e)
        }
      }
    }
  }
</script>
<style scoped></style>